/*eslint-disable */
<template>
  <b-container fluid class="ratingcontainer">
    <b-modal v-if="showModal" v-model="showModal" size="lg" :noCloseOnBackdrop="true" no-close-on-backdrop no-close-on-esc>
      <template #modal-header="">
          <h4 class=" mb-1 instituteTitle_block">
            Add Your Rating
          </h4>
          <b-button size="sm" class="closeRatingModal"  @click="showModal = false">
            <i class="fa-solid fa-xmark"></i>
          </b-button>
      </template>
      <b-row>
        <b-col col-md-12 lg="12">
            <iq-card class="ratingModalCard">
            <template v-slot:headerTitle v-if="!propOpenedInModal">
              <h4 class="card-title">{{ cvCardTitle }}</h4>
            </template>
            <template v-slot:body>
              <form action="#">
                <div class="form-row">
                  <div class="col-md-12 mb-3 rating_box" >
                      <div class="rating ratingInput center w-50">
                      <input v-model="vmRatingFormData.rating" type="radio" name="rating" value="5" id="5" @click="reviewObj.stars=5">
                      <label for="5">☆</label>
                      <input v-model="vmRatingFormData.rating" type="radio" name="rating" value="4" id="4" @click="reviewObj.stars=4">
                      <label for="4">☆</label>
                      <input v-model="vmRatingFormData.rating" type="radio" name="rating" value="3" id="3" @click="reviewObj.stars=3">
                      <label for="3">☆</label>
                      <input v-model="vmRatingFormData.rating" type="radio" name="rating" value="2" id="2" @click="reviewObj.stars=2">
                      <label for="2">☆</label>
                      <input v-model="vmRatingFormData.rating" type="radio" name="rating" value="1" id="1" @click="reviewObj.stars=1">
                      <label for="1">☆</label>
                    </div>
                  </div>
                  <div class="col-8 mb-3 align-self-center">
                    <label for="validationrating_comment">
                      {{ cvRatingCommentLabel }}</label>
                      <textarea  v-model="vmRatingFormData.rating_comment" type="text" class="form-control textarea" required></textarea>
                  </div>
                </div>
              </form>
            </template>
          </iq-card>
          <div class="form-group">
            <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
              <b-spinner label="Spinning"></b-spinner>
            </button>
            <button type="button" class="btn btn-primary float-right" @click="ratingAdd()">
              {{ cvSubmitBtn }}
            </button>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer="">
        <span>&nbsp;</span>
      </template>
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style lang="scss" scoped>
.ratingModalCard{
.rating{
  justify-content: center;
  /* background: #f1f1f1f1; */
  width: 159px;
  border-radius: 50px;
  margin: auto;
}
.rating > label{
  left: 0px;
  top: 4px;
}
.rating_box{
  display: flex;
    margin: auto;
  }
  textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
  .rating > label:hover:before,
  .rating > label:hover ~ label:before {
    opacity: 1 !important;
  }
  .rating:hover > input:checked ~ label:before {
    opacity: 0.4;
  }
}
.ratingcontainer{
  margin: auto;
}
.form-row{
  justify-content: center;
}
.submit{
  text-align: center;
  margin: auto;
  display: block;
}
.closeRatingModal i{
  margin-right: 0px !important;
}
</style>
<script>
import { Ratings } from "../../../FackApi/api/rating"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "RatingAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propModuleId: {
      type: String,
      default: null
    },
    propModuleName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      cvCardTitle: "Add rating",
      cvCardSubHeader: "Add Your rating ",
      cvSubmitBtn: "Add",
      cvRatingLabel: "Rating",
      cvRatingCommentLabel: "Add your feedback",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Rating",
      vmRatingFormData: Object.assign({}, this.initFormData()),
      vmRatingDesc: null,
      showModal: false,
      vmRatingType: null
    }
  },
  mounted () {
    if (this.propOpenedInModal) {
      this.showModal = true
    }
    this.vmRatingFormData.module_id = this.propModuleId
    this.vmRatingFormData.module_name = this.propModuleName
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_id": "",
        "module_name": "",
        "rating": "",
        "rating_comment": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmRatingFormData) {
          if (!this.vmRatingFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * ratingAdd
     */
    async ratingAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        let ratingAddResp = await Ratings.ratingAdd(this, this.vmRatingFormData)
        await ApiResponse.responseMessageDisplay(this, ratingAddResp)
        if (ratingAddResp && !ratingAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.showModal = false
          this.$emit("emitCloseRatingAddModal", ratingAddResp.resp_data)
        }
        this.vmRatingFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at ratingAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
