<template>
  <div class="w-full rounded-md">
    <button @click="shareModalOpen()" class="btn-opacity d-flex flex-fill justify-content-center align-items-center rounded-md">
      <i class="ri-share-line"></i> <span class="ml-2">Share</span>
    </button>
    <b-modal v-model="showModelSocialMediaShare" centered hide-footer hide-header  size="sm" >
      <template>
        <div class="m-2">
          <b-row>
            <div class="share-link py-1 px-2 mt-2 rounded" style="background-color: #f1f1f1; width:100%; overflow:hidden; word-wrap: break-word !important;">
              {{ shareUrl }}
              <input type="hidden" ref="share_link_url"  :value="shareUrl" />
            </div>
          </b-row>
          <div class="mt-2 grid">
            <facebook :url="shareUrl" scale="2.5" class="cursor">
            </facebook>
            <twitter :url="shareUrl" :title="shareTitle" scale="2.5" class="cursor">
            </twitter>
            <linkedin :url="shareUrl" scale="2.5" class="cursor">
            </linkedin>
            <telegram :url="shareUrl" scale="2.5" class="cursor">
            </telegram>
            <whats-app :url="shareUrl" :title="shareTitle" scale="2.5" class="cursor">
            </whats-app>
            <reddit :url="shareUrl" scale="2.5" :title="shareTitle" class="cursor">
            </reddit>
            <email :url="shareUrl" :subject="shareTitle" :body="shareEmailBody" scale="2.5" class="cursor">
            </email>
            <button @click="copyShareLink"  v-b-tooltip.hover.bottom :title="tooltipTittle" style=" outline:none;border:none; background:#fff;">
              <i class="fas fa-copy"  style="font-size:2rem;color:#ccc" size="3x"></i>
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Facebook, Twitter, Linkedin, Reddit, Telegram, WhatsApp, Email } from "vue-socialmedia-share"
export default {
  name: "SocialMediaShare",
  components: {
    Facebook,
    Twitter,
    Linkedin,
    Telegram,
    WhatsApp,
    Email,
    Reddit
  },
  props: {
    propPostId: {
      type: String
    },
    propShareUrl: {
      type: String
    }
  },
  mounted () {
    this.setShareUrl()
  },
  data () {
    return {
      SocialMediaShareTitle: "Share",
      showModelSocialMediaShare: false,
      tooltipTittle: "Copy Url",
      shareUrl: "",
      shareTitle: "",
      shareEmailBody: ""
    }
  },
  methods: {
    /**
     * setShareUrl
     */
    setShareUrl () {
      try {
        if (this.propPostId) {
          this.shareUrl = process.env.VUE_APP_SERVER_URL + "/content/post/" + this.propPostId
        }
        else if (this.propShareUrl) {
          this.shareUrl = this.propShareUrl
        }
      }
      catch (err) {
        console.error("Exception at setShareUrl () and Exception:", err.message)
      }
    },
    /**
     * shareModalOpen
     */
    shareModalOpen () {
      try {
        this.showModelSocialMediaShare = true
      }
      catch (err) {
        console.error("Exception at shareModalOpen () and Exception:", err.message)
      }
    },
    /*
     * copyShareLink
     * Copy the Data
     */
    copyShareLink () {
      let urlElement = this.$refs.share_link_url
      urlElement.setAttribute("type", "text")
      urlElement.select()

      try {
        document.execCommand("copy")
        this.tooltipTittle = "Copied"
      }
      catch (err) {
        this.tooltipTittle = "Copy Url"
      }
      finally {
        setTimeout(() => {
          this.tooltipTittle = "Copy Url"
        }, 2000)
      }

      /* unselect the range */
      urlElement.setAttribute("type", "hidden")
      window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style scoped>
  .btn-opacity{
    border: none ;
    outline : none;
    background: transparent;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: start;
    align-items: start;
    gap: 0.5rem 1rem;
  }
  .cursor{
    cursor: pointer;
  }
   .w-full{
    width: 100%;
  }
</style>
