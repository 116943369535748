/*eslint-disable */
<template>
  <b-modal size="xl" v-model="showSCModal" centered  :title="propAlertTitle" class="alertBoxModel" no-close-on-backdrop no-close-on-esc @hide="closeAlertModel">
    <b-container fluid class="p-0">
      <b-row>
        <b-col md="12">
          <b-card no-body>
            <b-card-text class="p-0">
              <div class="mt-3">
                <p v-html="propAlertDesc">
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="">
      <b-button size="sm" class="pull-left" variant="secondary" @click="closeAlertModel()">
        {{propCancelBtnText}}
      </b-button>
      <b-button v-if="!prophidebutton" size="sm" class="pull-left" variant="primary" @click="confirmAlertModel()">
        {{propOkBtnText}}
      </b-button>
    </template>
  </b-modal>
</template>
<style>
</style>
<script>
export default {
  name: "AlertBox",
  props: {
    propAlertDesc: {
      default: ""
    },
    propAlertTitle: {
      default: ""
    },
    propOkBtnText: {
      default: "Ok"
    },
    propCancelBtnText: {
      default: "Cancel"
    },
    prophidebutton: {
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      showSCModal: true
    }
  },
  computed: {
  },
  methods: {
    /**
     * closeAlertModel
     */
    closeAlertModel () {
      this.propshowSCModal = false
      this.$emit("emitCloseAlertModel", false)
    },
    /**
     * confirmAlertModel
     */
    confirmAlertModel () {
      this.$emit("emitConfirmAlertModel", true)
    }
  }
}
</script>
